
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ScrollTo } from "../common/Utils";

export default {
  props: {
    contentBlock: Object,
  },
  emits: ["OnClick"],
  setup(props: any, context: any) {
    const store = useStore();
    const route = useRoute();
    const getLang = () => {
      return route.params.lang;
    };

    function ScrollToBlock() {
      //console.log("TODO: Skip transition von block zu block");

      ScrollTo(store.state.blockPositions[props.contentBlock.id]);

      context.emit("OnClick");
    }

    function getDegree() {
      return "";
    }

    return { ScrollToBlock, route, getLang, getDegree };
  },
}; //
