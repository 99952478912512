
import BackIcon from "../icons/BackIcon.vue";
export default {
  components: {
    BackIcon,
  },
  props: {
    stage: Object,
  },
  setup() {
    const toTop = () => {
      window.scroll({
    top: 0,
    behavior: "smooth",
  });
    };
    return { toTop };
  },
}; //
