function ScrollTo(y:number, jumpDirectly:boolean = false){

  y += 100;

  if(jumpDirectly){
    window.scrollTo(0, y);

    return;
  }

  window.scroll({
    top: y,
    behavior: "smooth",
  });
}

export {ScrollTo}


function  DegreeToRadians(deg: number) {
  return deg * Math.PI / 180;
}

export {DegreeToRadians}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : { r: 0, g: 0, b: 0 }
  return r;
}
export {hexToRgb}

function componentToHex(c: any) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
export{componentToHex}

function rgbToHex(r: any, g: any, b: any) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export {rgbToHex}