<template>
  <div class="icon">
    
<svg viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M300 549C162.481 549 51 437.519 51 300C51 162.481 162.481 51 300 51C437.519 51 549 162.481 549 300C549 437.519 437.519 549 300 549Z" fill="white"/>
<g filter="url(#neurocure_icon_0_d)">
<path d="M300 417C235.383 417 183 364.617 183 300C183 235.383 235.383 183 300 183C364.617 183 417 235.383 417 300C417 364.617 364.617 417 300 417Z" fill="#CED47E"/>
</g>
<g filter="url(#neurocure_icon_1_d)">
<path d="M289.298 162.167C218.001 167.635 161.844 227.178 161.844 299.875C161.844 324.86 168.518 348.269 180.116 368.506L105.92 411.334C87.0255 378.508 76.226 340.466 76.226 299.875C76.226 179.874 170.678 81.939 289.298 76.3544V162.167Z" fill="#8AADD0"/>
<path d="M420.846 366.813C431.86 346.985 438.145 324.16 438.145 299.875C438.145 227.178 381.987 167.635 310.691 162.167V76.3544C429.311 81.939 523.763 179.874 523.763 299.875C523.763 339.766 513.313 377.204 495.022 409.641L420.846 366.813Z" fill="#8AADD0"/>
<path d="M192.175 386.233C217.491 417.795 256.369 438.031 299.976 438.031C344.302 438.031 383.706 417.133 409.002 384.695L483.606 427.757C443.171 485.705 376 523.649 299.976 523.649C224.651 523.649 158.006 486.425 117.454 429.372L192.175 386.233Z" fill="#8AADD0"/>
</g>
<defs>
<filter id="neurocure_icon_0_d" x="179" y="183" width="242" height="242" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="neurocure_icon_1_d" x="72.2256" y="76.3544" width="455.538" height="455.295" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>

<slot />
  </div>
</template>


<script>
export default {
  name : "NeuroCureIcon",
  props : {
    noShadow : {
      default : false
    }
  }
}
</script>