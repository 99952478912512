<template>
  <div class="icon">
    <svg viewBox="0 0 50 50">
      <g style="filter: url(#dropshadow)">
        <circle fill="#8AADD0" cx="23.5" cy="23.5" r="20" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.392 23.3L17.6 28.092l.908.908 4.792-4.792L28.092 29l.908-.908-4.792-4.792L29 18.508l-.908-.908-4.792 4.792-4.792-4.792-.908.908 4.792 4.792z"
          fill="#fff"
        />
      </g>
      <filter id="dropshadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
        <!-- stdDeviation is how much to blur -->
        <feOffset dx="0" dy="2" result="offsetblur" />
        <!-- how much to offset -->
        <feComponentTransfer>
          <feFuncA type="linear" slope=".5" />
          <!-- slope is the opacity of the shadow -->
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <!-- this contains the offset blurred image -->
          <feMergeNode in="SourceGraphic" />
          <!-- this contains the element that the filter is applied to -->
        </feMerge>
      </filter>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>