import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    onClick: $setup.ScrollToBlock,
    class: _normalizeClass(["link", { active: $props.contentBlock.id == $setup.route.params.id }]),
    to: '/' + $setup.getLang() + '/content/' + $props.contentBlock.id + '/' + $props.contentBlock.urlhandle
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createTextVNode(_toDisplayString($props.contentBlock.academicTitle) + " " + _toDisplayString($props.contentBlock.Title), 1)
    ]),
    _: 3
  }, 8, ["onClick", "class", "to"]))
}