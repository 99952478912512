import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6326a8d8")
const _hoisted_1 = { class: "nav-stage-button-inner" }
const _hoisted_2 = { class: "button-container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NeurocureIcon = _resolveComponent("NeurocureIcon")!
  const _component_IconAbout = _resolveComponent("IconAbout")!
  const _component_IconThousandNeuron = _resolveComponent("IconThousandNeuron")!
  const _component_IconMillionNeuron = _resolveComponent("IconMillionNeuron")!
  const _component_IconOneNeuron = _resolveComponent("IconOneNeuron")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavContentBlockButton = _resolveComponent("NavContentBlockButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav-stage", {
      active:
        this.$store.state.currentStage != null &&
        this.$store.state.currentStage.id == $props.stage.id,
      open: $setup.open,
      closed: !$setup.open,
    }])
  }, [
    _createVNode(_component_router_link, {
      to: '/' + $setup.route.params.lang + '/content/' + $props.stage.content_blocks[0].id,
      class: _normalizeClass(["nav-stage-button", {
        active:
          this.$store.state.currentStage != null &&
          this.$store.state.currentStage.id == $props.stage.id,
      }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.setStage()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          ($props.stage.id == 2 || $props.stage.id == 1)
            ? (_openBlock(), _createBlock(_component_NeurocureIcon, {
                key: 0,
                class: "inline-icon",
                noShadow: true
              }))
            : _createCommentVNode("", true),
          ($props.stage.id == 4 || $props.stage.id == 3)
            ? (_openBlock(), _createBlock(_component_IconAbout, {
                key: 1,
                class: "inline-icon"
              }))
            : _createCommentVNode("", true),
          ($props.stage.id == 10 || $props.stage.id == 7)
            ? (_openBlock(), _createBlock(_component_IconThousandNeuron, {
                key: 2,
                class: "inline-icon"
              }))
            : _createCommentVNode("", true),
          ($props.stage.id == 6 || $props.stage.id == 5)
            ? (_openBlock(), _createBlock(_component_IconMillionNeuron, {
                key: 3,
                class: "inline-icon"
              }))
            : _createCommentVNode("", true),
          ($props.stage.id == 9 || $props.stage.id == 8)
            ? (_openBlock(), _createBlock(_component_IconOneNeuron, {
                key: 4,
                class: "inline-icon"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString($props.stage.Title), 1)
        ])
      ]),
      _: 1
    }, 8, ["to", "class"]),
    _createElementVNode("div", _hoisted_2, [
      (
          this.$store.state.currentStage == $props.stage &&
          this.$store.state.currentStage != null
        )
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.getContentBlocks(), (block, index) => {
            return (_openBlock(), _createBlock(_component_NavContentBlockButton, {
              key: index,
              contentBlock: block,
              onOnClick: $setup.Hide
            }, null, 8, ["contentBlock", "onOnClick"]))
          }), 128))
        : _createCommentVNode("", true)
    ])
  ], 2))
}