import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2fb25500")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackIcon = _resolveComponent("BackIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "top-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toTop()))
  }, [
    _createVNode(_component_BackIcon, { class: "inline-icon" }),
    (_ctx.$route.params.lang=='de')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Zurück nach oben"))
      : _createCommentVNode("", true),
    (_ctx.$route.params.lang=='en')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Back to Top"))
      : _createCommentVNode("", true)
  ]))
}