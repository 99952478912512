import axios from 'axios';
import mainConfig from "../../main.config";


export default {
  Get( url: string ){
    return axios.get(mainConfig.API_URL + url);
  },
  Put( url: string, data:any ){

    return axios.put(mainConfig.API_URL + url, 
      data,
      {
        headers: {
          "Content-Type": 'application/json',
        }
      }
  );
  }
}