<template>
<div class="icon">
 <svg viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#about_0_d)">
<path d="M363.848 314.347C357.357 309.993 352.773 310.291 347.516 316.958C332.719 335.728 317.184 353.928 302.462 371.719C279.687 350.628 258.363 330.879 236.879 311C182.864 347.364 154.093 399.057 149.229 464.354C147.864 482.61 152.532 499.161 164.981 512.839C179.215 528.608 196.638 541.193 216.104 549.763C254.555 566.789 294.877 571.059 335.937 566.015C369.468 561.894 401.157 551.096 427.832 529.138C446.95 513.399 456.883 493.809 454.704 468.279C449.073 402.385 418.816 351.215 363.848 314.347Z" fill="white"/>
</g>
<g filter="url(#about_1_d)">
<path d="M304.008 570C272.387 570 242.544 563.846 214.975 551.614C195.331 542.905 177.742 530.158 163.355 514.205C151.189 500.794 145.78 484.063 147.23 464.498C152.181 397.958 181.856 345.848 235.544 309.617L236.479 309L301.828 369.649C305.658 365.01 309.533 360.334 313.45 355.621C324.194 342.715 335.302 329.378 345.933 315.855C351.473 308.813 356.751 307.962 364.154 312.937C391.723 331.464 413.387 353.919 428.556 379.675C443.726 405.43 452.888 435.301 455.695 468.408C457.819 493.322 448.864 513.682 428.313 530.656C403.514 551.118 373.203 563.323 335.667 567.952C325.166 569.282 314.593 569.966 304.008 570ZM236.199 312.601C183.868 348.288 154.885 399.473 150.028 464.732C148.634 483.511 153.772 499.532 165.432 512.344C179.563 527.995 196.832 540.498 216.116 549.042C252.239 565.071 292.329 570.552 335.274 565.23C372.286 560.666 402.12 548.677 426.498 528.551C446.291 512.223 454.918 492.648 452.878 468.698C447.31 403.457 416.933 351.852 362.581 315.322C356.405 311.17 352.765 311.759 348.132 317.642C337.473 331.202 326.355 344.557 315.594 357.472C311.373 362.551 307.171 367.591 303.035 372.604L302.099 373.745L236.199 312.601Z" fill="#89AED1"/>
</g>
<g filter="url(#about_2_d)">
<path d="M297.751 309.999C346.252 309.746 381.426 272.763 380.996 227.598C381.061 180.971 345.365 144.953 299.125 145C252.885 145.047 217.244 180.343 217.001 226.061C216.749 272.135 252.968 310.224 297.751 309.999Z" fill="white"/>
</g>
<g filter="url(#about_3_d)">
<path d="M297.844 311C287.271 310.985 276.806 308.877 267.055 304.797C257.304 300.718 248.462 294.748 241.041 287.235C224.894 270.96 215.887 248.956 216.001 226.059C216.123 203.674 224.753 182.95 240.312 167.691C255.871 152.432 276.928 144.019 299.621 144H299.705C322.361 144 343.362 152.506 358.846 167.952C374.33 183.397 383.016 204.709 382.988 227.598C383.172 238.402 381.199 249.134 377.186 259.17C373.173 269.207 367.2 278.347 359.613 286.06C343.839 302.018 322.061 310.879 298.283 311H297.844ZM299.714 146.798H299.63C254.553 146.798 219.059 181.662 218.816 226.078C218.759 237.045 220.868 247.916 225.024 258.071C229.179 268.225 235.298 277.463 243.033 285.258C250.195 292.509 258.729 298.271 268.139 302.209C277.549 306.148 287.648 308.185 297.853 308.202H298.246C321.266 308.081 342.342 299.519 357.583 284.092C364.917 276.644 370.693 267.815 374.575 258.12C378.456 248.424 380.366 238.055 380.192 227.617C380.192 205.456 371.936 184.974 356.863 169.938C341.791 154.903 321.613 146.798 299.705 146.798H299.714Z" fill="#89AED1"/>
</g>
<g filter="url(#about_4_d)">
<path d="M487.782 255.167C480.98 250.445 476.693 250.875 471.57 257.323C460.191 271.62 448.248 285.469 435.49 300.755L402.252 265.545L385 296.696C436.353 334.715 468.081 384.176 478.681 447C503.87 444.891 535.439 427.626 547.644 408.738C551.885 402.205 555.768 393.993 555.947 386.462C557.279 330.59 533.694 287.13 487.782 255.167Z" fill="white"/>
</g>
<g filter="url(#about_5_d)">
<path d="M477.211 449L476.996 447.724C466.568 385.507 436.144 336.363 383.984 297.475L383 296.743L401.739 262.651L435.171 298.31C438.575 294.219 441.92 290.212 445.205 286.289C453.929 275.863 462.164 266.019 470.166 255.894C475.703 248.884 480.782 248.199 488.268 253.454C535.116 286.299 558.259 331.136 556.947 386.708C556.75 395.154 552.206 403.956 548.43 409.831C536.203 428.862 504.177 446.767 478.486 448.934L477.211 449ZM386.645 295.955C412.065 315.07 432.725 337.245 448.054 361.822C463.626 386.793 474.213 415.095 479.545 445.969C504.262 443.388 534.46 426.347 546.069 408.273C549.648 402.699 553.949 394.413 554.136 386.605C555.429 332.065 532.727 288.072 486.675 255.744C480.435 251.38 476.968 251.84 472.386 257.621C464.357 267.784 456.111 277.637 447.379 288.082C443.762 292.398 440.083 296.812 436.342 301.322L435.32 302.543L402.349 267.389L386.645 295.955Z" fill="#89AED1"/>
</g>
<g filter="url(#about_6_d)">
<path d="M213 294.813C206.689 283.858 200.947 273.882 194.383 262.497L163.37 300.53C147.526 283.932 131.615 267.242 115.162 250C79.2881 277.086 52.8344 308.45 47.8151 354.076C46.1296 369.394 41.4099 384.665 50.0062 399.291C66.3093 427.013 93.0816 438.463 124.096 447C134.406 384.086 162.48 332.519 213 294.813Z" fill="white"/>
</g>
<g filter="url(#about_7_d)">
<path d="M125.505 449L123.999 448.588C95.2241 440.634 66.2435 429.266 49.1861 400.137C41.9573 387.804 43.8375 374.966 45.661 362.55C46.0725 359.742 46.5022 356.832 46.8108 353.988C51.2715 313.313 72.1824 280.862 114.62 248.748L115.62 248L163.52 298.379L194.782 259.912L215 295.131L213.999 295.88C165.371 332.317 136.475 381.9 125.767 447.465L125.505 449ZM115.302 251.752C74.2112 283.108 53.928 314.772 49.5889 354.296C49.2709 357.188 48.8405 360.126 48.4291 362.952C46.5587 375.443 44.866 387.234 51.5898 398.724C66.9919 424.999 91.381 436.517 123.242 445.463C128.563 413.92 138.401 385.446 152.494 360.818C167.398 334.913 187.41 312.31 211.315 294.383L194.37 264.862L163.678 302.627L115.302 251.752Z" fill="#89AED1"/>
</g>
<g filter="url(#about_8_d)">
<path d="M408.09 223.848C408.229 233.735 412.613 236.606 420.346 238.318C465.513 248.355 502.23 211.735 499.894 174.657C498.806 167.885 498.881 160.626 496.424 154.406C487.435 131.546 471.04 116.944 446.4 113.006C419.238 108.666 398.106 118.441 382.195 140.871C377.607 147.334 377.998 151.712 383.125 157.755C399.035 176.08 407.891 199.526 408.09 223.848Z" fill="white"/>
</g>
<g filter="url(#about_9_d)">
<path d="M435.248 242C430.076 241.997 424.92 241.431 419.871 240.313C411.661 238.504 406.55 235.215 406.391 224.467C406.236 200.42 397.418 177.23 381.546 159.132C375.825 152.478 375.534 147.428 380.503 140.493C397.12 117.262 419.391 107.694 446.698 112.005C471.346 115.913 488.696 130.166 498.286 154.353C500.052 158.813 500.569 163.724 501.104 168.512C501.33 170.64 501.556 172.842 501.894 174.941V175.072C503.059 193.73 494.548 212.724 479.134 225.881C466.916 236.331 451.341 242.052 435.248 242ZM433.998 113.776C413.22 113.776 396.425 123.147 382.795 142.123C378.615 147.971 378.83 151.654 383.687 157.305C400.257 176.581 408.842 199.165 409.209 224.42C409.34 233.463 413.107 235.937 420.481 237.539C440.996 242.065 461.709 237.024 477.292 223.717C492.021 211.151 500.156 193.046 499.076 175.288C498.729 173.132 498.493 170.921 498.268 168.784C497.77 163.986 497.328 159.46 495.666 155.355C486.469 132.153 469.843 118.499 446.257 114.76C442.203 114.109 438.104 113.78 433.998 113.776Z" fill="#89AED1"/>
</g>
<g filter="url(#about_10_d)">
<path d="M180.527 238.68C184.196 237.646 189.433 232.719 189.443 229.588C189.508 198.852 201.188 173.043 221 150.929C207.714 122.976 181.198 108.299 153.115 112.803C116.535 118.67 91.8599 154.304 99.3382 190.475C106.555 225.376 145.301 248.646 180.527 238.68Z" fill="white"/>
</g>
<g filter="url(#about_11_d)">
<path d="M164.079 242C151.487 241.945 139.151 238.438 128.408 231.86C112.789 222.356 101.835 207.339 98.3737 190.677C96.5575 181.964 96.5421 172.971 98.3281 164.252C100.114 155.533 103.664 147.272 108.758 139.98C113.901 132.522 120.495 126.183 128.145 121.341C135.794 116.499 144.342 113.256 153.274 111.804C181.965 107.234 209.173 122.411 222.59 150.474L223 151.325L222.366 152.016C201.456 175.238 191.258 200.508 191.193 229.272C191.193 233.113 185.501 238.458 181.265 239.655C175.67 241.218 169.888 242.007 164.079 242ZM163.342 113.804C160.116 113.804 156.896 114.063 153.712 114.579C145.14 115.922 136.933 119.009 129.598 123.651C122.263 128.293 115.954 134.391 111.063 141.568C106.185 148.551 102.786 156.459 101.075 164.807C99.3648 173.154 99.3786 181.765 101.116 190.107C104.419 205.993 114.898 220.357 129.864 229.459C145.39 238.907 163.846 241.608 180.51 236.935C183.822 236 188.384 231.543 188.393 229.244C188.459 200.461 198.975 174.108 219.661 150.829C208.203 127.606 186.826 113.804 163.342 113.804Z" fill="#89AED1"/>
</g>
<defs>
<filter id="about_0_d" x="145" y="311" width="314" height="265" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_1_d" x="143" y="309" width="317" height="269" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_2_d" x="213" y="145" width="172" height="173" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_3_d" x="212" y="144" width="175" height="175" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_4_d" x="381" y="252" width="179" height="203" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_5_d" x="379" y="250" width="182" height="207" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_6_d" x="41" y="250" width="176" height="205" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_7_d" x="40" y="248" width="179" height="209" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_8_d" x="375" y="112" width="129" height="136" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_9_d" x="373" y="111" width="133" height="139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_10_d" x="94" y="112" width="131" height="137" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="about_11_d" x="93" y="111" width="134" height="139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>


</div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>