<template>
  <div class="loading">
    <div class="loading-inner" :style="{width: store.state.loadingProgress * 100 + '%'}"></div>

      <span v-if="!store.state.sceneReady && store.state.loadingProgress < 1">{{(store.state.loadingProgress * 100).toFixed(0)}}%</span>
      <span v-if="store.state.loadingProgress == 1">Nervenzellen werden aktiviert</span>      
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch } from "vue";
export default {
  setup() {
    const store = useStore();
    var progress = ref(1000);

    watch(
      () => store.state.loadingProgress,
      async () => {

        let loadingProgress = store.state.loadingProgress; 

        let c = Math.PI * 70 * 2;
        let pct = c - (loadingProgress * c);


        progress.value = pct;
      }
    );
    return { store, progress };
  },
};
</script>



<style lang="scss" scoped>

circle {
  transform: rotate(-90deg);
  transform-origin: center center;
  //transition: stroke-dashoffset 1s linear;
}

.loading {
  margin-top: 1rem;
  padding: 1rem 2rem;
  display: flex;
  font-size: 1rem;
  font-family: "Signika", sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background:$white;
  width:280px;
  color:$black;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: wait;

  .loading-inner {
    background:$blue;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    
    height: 100%;
    width: 20%;
  }

  span {
    position: relative;
  }
}
</style>