<template>
  <div class="icon">
    <svg viewBox="0 0 48 55" fill="none">
      <path d="M48 27.5L.75 54.78V.22L48 27.5z" fill="#ffffff" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "PlayIcon",
};
</script>