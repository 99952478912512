
import { ref } from "@vue/reactivity";
//import { ref } from "vue";
//import { Stage } from "../enums";
import { useStore } from "vuex";
import IconAbout from "../icons/IconAbout.vue";
import IconMillionNeuron from "../icons/IconMillionNeuron.vue";
import IconOneNeuron from "../icons/IconOneNeuron.vue";
import IconThousandNeuron from "../icons/IconThousandNeuron.vue";
import NeurocureIcon from "../icons/neurocure_icon.vue";
import NavContentBlockButton from "./NavContentBlockButton.vue";
import { useRoute, useRouter } from 'vue-router';
import { ScrollTo } from "../common/Utils";

export default {
  components: {
    NavContentBlockButton,
    IconAbout,
    IconOneNeuron,
    IconThousandNeuron,
    IconMillionNeuron,
    NeurocureIcon,
  },
  props: {
    stage: Object,
  },
  setup(props: any) {
    const store = useStore();
    const open = ref(false);
    const router = useRouter();
    const route = useRoute();

    const getContentBlocks = () => {
      return props.stage.content_blocks;
    };

    const Hide = ()=>{
      open.value = false; 
    }

    const setStage = () => {
      //console.log("open", open.value);
      open.value = !open.value;
      store.commit("SetCurrentStage", props.stage);

//      console.log(props.stage.content_blocks[0].id);

      ScrollTo( store.state.blockPositions[props.stage.content_blocks[0].id], true );

      console.log("content_blocks" , props.stage.content_blocks[0]);

      router.push({params:{id : props.stage.content_blocks[0].id , title : props.stage.content_blocks[0].urlhandle}});



    };
    return { getContentBlocks, setStage, store, open, Hide,route };
  },
}; //
